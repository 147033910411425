<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-form @submit.prevent="saveForm" method="post" id="resource-form">
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Resource</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            label="Title"
            v-model="fields.title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>

          <v-textarea
            label="Intro Text"
            v-model="fields.intro_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('intro_text')"
            :error-messages="errors['intro_text']"
          ></v-textarea>

          <v-textarea
            label="Article Summary"
            v-model="fields.article_summary"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('article_summary')"
            :error-messages="errors['article_summary']"
          ></v-textarea>

          <tiptap
            class="mb-5"
            label="Article Text"
            v-model="fields.article_text"
            :error="errors.hasOwnProperty('article_text')"
            :error-messages="errors['article_text']"
          />

          <v-switch
            label="Featured Article"
            v-model="fields.featured_article"
            inset
            :error="errors.hasOwnProperty('featured_article')"
            :error-messages="errors['featured_article']"
          ></v-switch>

          <v-text-field
            label="Featured short text"
            v-model="fields.featured_short_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('featured_short_text')"
            :error-messages="errors['featured_short_text']"
          ></v-text-field>

          <v-text-field
            label="Footer link short text"
            v-model="fields.footer_link_short_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('footer_link_short_text')"
            :error-messages="errors['footer_link_short_text']"
          ></v-text-field>

          <v-switch
            label="Footer link"
            v-model="fields.footer_link"
            inset
            :error="errors.hasOwnProperty('footer_link')"
            :error-messages="errors['footer_link']"
          ></v-switch>

          <v-file-input
            label="Image"
            v-model="fields.image"
            placeholder="Choose a Image"
            outlined
            @change="imageHasChanged()"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="resource-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },

  data() {
    return {
      fields: {
        title: null,
        image_has_changed: false,
        article_summary: null,
        featured_article: false,
        featured_short_text: null,
        footer_link_short_text: null,
        footer_link: false,
        image: null,
      },
      errors: {},
      dialog: false,
      loading: false,
      isEditing: false,
    };
  },

  methods: {
    open(resource = null) {
      if (resource !== null) {
        this.resource = resource;
        this.fields.footer_link = resource.footer_link;
        this.fields.footer_link_short_text = resource.footer_link_short_text;
        this.fields.featured_short_text = resource.featured_short_text;
        this.fields.featured_article = resource.featured_article;
        this.fields.article_summary = resource.article_summary;

        this.fields.article_text = resource.article_text;
        this.fields.intro_text = resource.intro_text;

        this.fields.title = resource.title;

        this.fields.image = resource.image
          ? new File([resource.image], resource.image)
          : null;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    imageHasChanged() {
      this.fields.image_has_changed = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.resourceId = this.resource.id;
      }

      this.$store
        .dispatch("lhl/resources/saveResource", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.title = null;
      this.fields.image_has_changed = false;
      this.fields.article_summary = null;
      this.fields.featured_article = false;
      this.fields.featured_short_text = null;
      this.fields.footer_link_short_text = null;
      this.fields.footer_link = false;
      this.fields.image = null;
      this.fields.image_has_changed = false;
    },
  },
};
</script>
