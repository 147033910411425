<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>{{ resource.title }}</h1>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Basic Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  small
                  @click="$refs.resourceForm.open(resource)"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-simple-table class="mb-5 table" divider="true">
                  <tbody>
                    <tr>
                      <th>Intro Text</th>
                      <td>{{ resource.intro_text }}</td>
                    </tr>
                    <tr>
                      <th>Article Text</th>
                      <td>
                        <div v-html="resource.article_text"></div>
                      </td>
                    </tr>
                    <tr>
                      <th>Article Summary</th>
                      <td>{{ resource.article_summary }}</td>
                    </tr>
                    <tr>
                      <th>Featured Article</th>
                      <td>{{ resource.featured_article ? "Yes" : "No" }}</td>
                    </tr>

                    <tr>
                      <th>Featured Short Text</th>
                      <td>{{ resource.featured_short_text }}</td>
                    </tr>
                    <tr>
                      <th>Footer Link Short Text</th>
                      <td>{{ resource.footer_link_short_text }}</td>
                    </tr>
                    <tr>
                      <th>Footer Link</th>
                      <td>{{ resource.footer_link ? "Yes" : "No" }}</td>
                    </tr>
                    <tr>
                      <th>Image</th>
                      <td>
                        <v-card v-if="resource.image">
                          <v-img
                            :src="resource.asset_urls.image"
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                          >
                          </v-img>
                        </v-card>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <ResourceForm ref="resourceForm" />
  </div>
</template>

<script>
import ResourceForm from "./components/ResourceForm";

export default {
  data() {
    return {
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
      },
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Resources",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-resources",
            params: { id: this.$route.params.id },
          },
        },
      ],
    };
  },

  components: {
    ResourceForm,
  },

  computed: {
    resource() {
      return this.$store.state.lhl.resources["resource"];
    },
  },

  methods: {
    openDelete(image) {
      this.deleteDialog.image = image;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("lhl/resources/deleteImage", {
          appId,
          resourceId: this.$route.params.resourceId,
          imageId: this.deleteDialog.image.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
